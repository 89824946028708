import React from 'react';

const CardProductsExport = ({ className, children }) => (

  <div className={ `flex-1 flex flex-col justify-between ${className} `}>
    {children}
  </div>
);

export default CardProductsExport;
